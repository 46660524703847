html, body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#firebaseui-container {
  margin: 2rem 0;
}

.firebaseui-title {
  visibility: hidden;
  position: relative;
}

.firebaseui-title:after {
  visibility: visible;
  position: absolute;
  top: 0;
  left: 0;
  content: 'Sign In';
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.firebaseui-label {
  /* display: none; */
  color: #1FCCCD;
  font-weight: bold;
  margin: 1em 0;
}

.firebaseui-textfield {
  padding: 1rem;
  background-color: #f4f4f4;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  min-width: 300px;
  border-radius: 4px;
}

.firebaseui-input {
  outline: none;
  font-size: 1em;
  background-color: #f4f4f4;
  border: none;
  font-weight: 600;
  width: 100%;
  margin-top: 0.5rem;
}

.firebaseui-input:focus {
  outline: none;  
}

.firebaseui-button {
  border: none;
  background-color: #ffffff;
  color: #1FCCCD;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 7px 14px;
  border-radius: 4px;
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: bold;
  margin: 2rem 0 0 2rem;
}
.firebaseui-button:hover {
  background-color: #f4f4f4;
  cursor: pointer;
}

.firebaseui-tos {
  display: none;
}

.firebaseui-form-actions {
  text-align: right;
}
